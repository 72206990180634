export interface Modal {
  close(shouldRefresh: boolean): void;
  confirm(): void;
  type: ModalTypeName;
}

export interface ModalWithAction<T> {
  close(action: T): void;
  confirm(): void;
  type: ModalTypeName;
}

export interface GenericModal<T> {
  close(): void;
  confirm(action: T): void;
  type: ModalTypeName;
}

export interface GenericModalConfig {
  type: ModalTypeName;
  title: string;
  text: string;
  buttons: GenericModalButton[];
  customContent?: {
    content: string;
    style: string;
  };
}

export interface GenericModalButton {
  label: string;
  action: string | boolean;
  color?: string;
  type: string;
  size: string;
}

export type PSOButtonsSlug = 'ghost' | 'default';

export class PSOButtons {
  public static DEFAULT: PSOButtonsSlug = 'default';
  public static GHOST: PSOButtonsSlug = 'ghost';
}

export type ModalTypeName = 'info' | 'error' | 'alert' | 'success';

export class ModalType {
  public static INFO: ModalTypeName = 'info';
  public static ERROR: ModalTypeName = 'error';
  public static ALERT: ModalTypeName = 'alert';
  public static SUCCESS: ModalTypeName = 'success';
}
