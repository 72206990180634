import { RoleSlug, RouteRolePrefix } from '@core/Roles';
import { UserResource } from '@data/users/users.model';
import { SurveyStatusValue } from '@data/survey/survey.model';
import { EnumResource } from '@shared/interfaces/enum-resource';

export interface ProfileStateModel {
  id: number | null;
  email: string | null;
  email_verified: boolean | null;
  name: string | null;
  first_name: string | null;
  last_name: string | null;
  roles: RoleSlug[];
  workshops: number[];
  fleets: number[];
  currentRole: RoleSlug;
  routeRolePrefix: RouteRolePrefix | null;
  impersonated_by: UserResource;
  survey_status: EnumResource<SurveyStatusValue>;
}

export class GetProfileData {
  static readonly type = '[Profile] Get Profile Data';
}

export class ClearProfileData {
  static readonly type = '[Profile] Clear Profile';
}
