import { Injectable } from '@angular/core';
import { Roles, RoleSlug, RouteRolePrefix } from '@core/Roles';
import { UserResource } from '@data/users/users.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DataResource } from '@shared/interfaces/data-resource';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ProfileResource } from './profile-resource.interface';
import { ProfileResourceService } from './profile-resource.service';
import { ClearProfileData, GetProfileData, ProfileStateModel } from './profile.actions';
import { SurveyStatusValue } from '@data/survey/survey.model';
import { EnumResource } from '@shared/interfaces/enum-resource';

@State<ProfileStateModel>({
  name: 'profile',
  defaults: {
    id: null,
    email: null,
    email_verified: null,
    name: null,
    first_name: null,
    last_name: null,
    roles: null,
    workshops: [],
    fleets: [],
    currentRole: null,
    routeRolePrefix: null,
    impersonated_by: null,
    survey_status: null,
  },
})
@Injectable()
export class ProfileState {
  @Selector()
  static profile(state: ProfileStateModel): ProfileStateModel {
    return state;
  }

  @Selector()
  static hasEmail(state: ProfileStateModel): boolean {
    return typeof state.email === 'string';
  }

  @Selector()
  static emailVerified(state: ProfileStateModel): boolean {
    return state.email_verified;
  }

  @Selector()
  static profileId(state: ProfileStateModel): number {
    return state.id;
  }

  @Selector()
  static routeRolePrefix(state: ProfileStateModel): RouteRolePrefix {
    return state.routeRolePrefix;
  }

  @Selector()
  static routeRolePrefixWithSlashes(state: ProfileStateModel): string {
    return '/' + state.routeRolePrefix + '/';
  }

  @Selector()
  static roles(state: ProfileStateModel): RoleSlug[] {
    return state.roles;
  }

  @Selector()
  static workshops(state: ProfileStateModel): number[] {
    return state.workshops;
  }

  @Selector()
  static currentRole(state: ProfileStateModel): RoleSlug {
    return state.currentRole;
  }

  @Selector()
  static impersonatedBy(state: ProfileStateModel): UserResource {
    return state.impersonated_by;
  }

  @Selector()
  static profileName(state: ProfileStateModel): string {
    return state.name;
  }

  @Selector()
  static surveyStatus(state: ProfileStateModel): EnumResource<SurveyStatusValue> {
    return state.survey_status;
  }

  @Action(GetProfileData)
  getProfileData(ctx: StateContext<ProfileStateModel>): Observable<DataResource<ProfileResource>> {
    return this.profileResourceService.getProfile().pipe(
      tap((result: DataResource<ProfileResource>) => {
        ctx.patchState({
          id: result?.data?.id,
          email: result?.data?.email,
          email_verified: result?.data?.email_verified,
          name: result?.data?.name,
          first_name: result?.data?.first_name,
          last_name: result?.data?.last_name,
          roles: result?.data?.roles,
          workshops: result?.data?.workshops,
          fleets: result?.data?.fleets,
          routeRolePrefix: result?.data?.roles && result?.data?.roles[0] ? Roles.prefix(result?.data?.roles[0]) : null,
          currentRole: result?.data?.roles && result?.data?.roles[0] ? result?.data?.roles[0] : null,
          impersonated_by: result?.data?.impersonated_by || null,
          survey_status: null, //result?.data?.survey_status || null, // INFO: disable survey
        });
      })
    );
  }

  @Action(ClearProfileData)
  clearProfileData(ctx: StateContext<ProfileStateModel>): ProfileStateModel {
    return ctx.patchState({
      id: null,
      email: null,
      email_verified: null,
      name: null,
      first_name: null,
      last_name: null,
      roles: null,
      workshops: null,
      fleets: null,
      routeRolePrefix: null,
      currentRole: null,
      impersonated_by: null,
      survey_status: null,
    });
  }

  constructor(private profileResourceService: ProfileResourceService) {}
}
