import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Id } from '@app/@core/http/crud-model';
import { UserType } from '@app/@data/users/users.model';
import { Modal, ModalType } from '@app/@pso-ui/modal';

@Component({
  selector: 'app-impersonate-modal',
  templateUrl: './impersonate-modal.component.html',
  styleUrls: ['./impersonate-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpersonateModalComponent implements Modal {
  control: FormControl<Id> = new FormControl<Id>(null);
  type = ModalType.INFO;
  isLoading = false;
  UserType = UserType;

  constructor(
    private readonly dialogRef: MatDialogRef<ImpersonateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: { supervisorId: Id }
  ) {
    if (this.data?.supervisorId) {
      this.control.setValue(String(this.data?.supervisorId));
    }
  }

  confirm(): void {
    this.dialogRef.close(this.control?.value);
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
